.responsive-image {
    width: 100%;
    height: auto;
    max-width: 30rem;
    /* Default max-width */
    max-height: 30rem;
    /* Default max-height */
    object-fit: contain;
}

/* Adjust the maxWidth and maxHeight based on screen size */
@media (max-width: 600px) {
    .responsive-image {
        max-width: 20rem;
        max-height: 20rem;
    }
}

@media (max-width: 400px) {
    .responsive-image {
        max-width: 15rem;
        max-height: 15rem;
    }
}

@media (min-width: 1200px) {
    .responsive-image {
        max-width: 40rem;
        max-height: 40rem;
    }
}